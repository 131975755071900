.list__row {
    overflow: hidden;
    background: white;
}
.list__rowInner {
    width: 100000px;
}
.list__part {
    -webkit-animation-name: marquee-text-animation;
            animation-name: marquee-text-animation;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    float: left;
    -webkit-animation-duration: 20s;
            animation-duration: 20s;
}
@-webkit-keyframes marquee-text-animation {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}
@keyframes marquee-text-animation {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}

