.chat-block {
    padding: 20px 0;
}
.bit-chat {
    background-image: url('../../img/heading-icon.png');
    background-position: 0 0;
}

.chat-block h2,
.chat-block p {
    text-align: center;
}
.chat-block h2 {
    font-size: 2em;
    line-height: 1em;
    margin-bottom: 0.5em;
}
.chat-header {
    padding: 5px 20px;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: #FCD535;
    display: flex;
    align-items: center;
}
.chat-inner {
    margin-top: 50px;
}
.chat-header span {
    margin: 0 5px;
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: bold;
}
.chat-header p {
    font-size: 0.9em;
}
.chat-content {
    background: rgb(248,249,250);
    padding: 10px;
}
.chat-content__inner {
    height: 500px;
    overflow-x: hidden;
    background: #EAEAEB;
    padding: 20px 30px;
}
.chat-content__inner p {
    text-align: start;
    margin-bottom: 1rem;
}
.chat-content__inner .item {
    margin-bottom: 20px;
}
.chat-content__inner .people {
    display: flex;
    justify-content: start;
}
.chat-content__inner .user {
    display: flex;
    justify-content: end;
}
.msg_container {
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 5px;
    background-color: #fff;
    padding: 10px;
    position: relative;
}
.fw-lighter {
    font-weight: lighter!important;
}
.small, small {
    font-size: .875em;
}
.msg_time {
    position: absolute;
    left: 0;
    bottom: -15px;
    color: rgba(53,53,53,.5);
    font-size: 10px;
}
.chat-input {
    background: rgba(53,53,53,.1);
    padding: 10px;
}
.chat-input form {
    display: flex;
    align-items: center;
    width: 100%;
}
.chat-input textarea {
    flex: 1;
    border: none;
    border-radius: 10px;
    resize: none;
    height: 50px;
    padding: 10px 20px;
}
.chat-input button {
    width: 50px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 25px;
}

@media (max-width: 768px) {
    .chat-content__inner {
        padding: 20px;
    }
    .chat-block h2 {
        font-size: 1.8em;
        line-height: 1em;
    }
}