.final-block-inner {
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
    padding: 20px;
    border-radius: 10px;
}
.innerFinal {
    max-width: 600px;
    width: 100%;
    margin: 20px auto;
}

.btn-anim {
    animation: animate 3s linear infinite;
    cursor: pointer;
}
.mb-2 {
    margin-bottom: 15px;
}
.finalRow {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    margin: 20px 0;
}
.finalRow .col {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}
.borderRed {
    padding: 5px 10px;
    border: 1px solid #dc3545;
    color: #dc3545;
    font-weight: bold;
}
.btn-center {
    text-align: center;
    margin-right: auto;
    margin-left: auto;
}
.fwb {
    font-weight: bold;
}