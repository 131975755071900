

.moderator-block {
    margin: 30px 0;
    padding-top: 50px;
    padding-bottom: 20px;
    border-bottom: 1px solid #c1c1c1;
}
.loading-block {
    -webkit-transition: -webkit-transform .5s ease;
    transition: -webkit-transform .5s ease;
    -o-transition: transform .5s ease;
    transition: transform .5s ease;
    transition: transform .5s ease, -webkit-transform .5s ease;
}
.loading-block.invisible {
    -webkit-transform: scale(0);
        -ms-transform: scale(0);
            transform: scale(0);
}
.cssload-container {
    width: 117px;
    height: 88px;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
}
.loading-block__inner {
    position: relative;
    width: 117px;
    height: 88px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.my-auto {
    margin-bottom: auto!important;
}
.bit-loading {
    background-color: #ff9800;
}
.cssload-dot {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 24px;
    position: absolute;
    bottom: 29px;
    left: 0;
    -webkit-transform-origin: center bottom;
        -ms-transform-origin: center bottom;
            transform-origin: center bottom;
    -webkit-animation: cssload-dot .69s ease-in-out infinite;
            animation: cssload-dot .69s ease-in-out infinite;
}
.cssload-dot svg {
    width: 0.75em;
}
@-webkit-keyframes wave {
    0%,60%,to {
        -webkit-transform: none;
                transform: none
    }

    30% {
        -webkit-transform: translateY(-4px);
                transform: translateY(-4px)
    }
}
@keyframes wave {
    0%,60%,to {
        -webkit-transform: none;
                transform: none
    }

    30% {
        -webkit-transform: translateY(-4px);
                transform: translateY(-4px)
    }
}

@-webkit-keyframes bounce {
    0% {
        -webkit-transform: matrix(0,0,0,0,0,0);
                transform: matrix(0,0,0,0,0,0)
    }

    4.7% {
        -webkit-transform: matrix(.45,0,0,.45,0,0);
                transform: matrix(.45,0,0,.45,0,0)
    }

    9.41% {
        -webkit-transform: matrix(.883,0,0,.883,0,0);
                transform: matrix(.883,0,0,.883,0,0)
    }

    14.11% {
        -webkit-transform: matrix(1.141,0,0,1.141,0,0);
                transform: matrix(1.141,0,0,1.141,0,0)
    }

    18.72% {
        -webkit-transform: matrix(1.212,0,0,1.212,0,0);
                transform: matrix(1.212,0,0,1.212,0,0)
    }

    24.32% {
        -webkit-transform: matrix(1.151,0,0,1.151,0,0);
                transform: matrix(1.151,0,0,1.151,0,0)
    }

    29.93% {
        -webkit-transform: matrix(1.048,0,0,1.048,0,0);
                transform: matrix(1.048,0,0,1.048,0,0)
    }

    35.54% {
        -webkit-transform: matrix(.979,0,0,.979,0,0);
                transform: matrix(.979,0,0,.979,0,0)
    }

    41.04% {
        -webkit-transform: matrix(.961,0,0,.961,0,0);
                transform: matrix(.961,0,0,.961,0,0)
    }

    52.15% {
        -webkit-transform: matrix(.991,0,0,.991,0,0);
                transform: matrix(.991,0,0,.991,0,0)
    }

    63.26% {
        -webkit-transform: matrix(1.007,0,0,1.007,0,0);
                transform: matrix(1.007,0,0,1.007,0,0)
    }

    85.49% {
        -webkit-transform: matrix(.999,0,0,.999,0,0);
                transform: matrix(.999,0,0,.999,0,0)
    }

    to {
        -webkit-transform: matrix(1,0,0,1,0,0);
                transform: matrix(1,0,0,1,0,0)
    }
}

@keyframes bounce {
    0% {
        -webkit-transform: matrix(0,0,0,0,0,0);
                transform: matrix(0,0,0,0,0,0)
    }

    4.7% {
        -webkit-transform: matrix(.45,0,0,.45,0,0);
                transform: matrix(.45,0,0,.45,0,0)
    }

    9.41% {
        -webkit-transform: matrix(.883,0,0,.883,0,0);
                transform: matrix(.883,0,0,.883,0,0)
    }

    14.11% {
        -webkit-transform: matrix(1.141,0,0,1.141,0,0);
                transform: matrix(1.141,0,0,1.141,0,0)
    }

    18.72% {
        -webkit-transform: matrix(1.212,0,0,1.212,0,0);
                transform: matrix(1.212,0,0,1.212,0,0)
    }

    24.32% {
        -webkit-transform: matrix(1.151,0,0,1.151,0,0);
                transform: matrix(1.151,0,0,1.151,0,0)
    }

    29.93% {
        -webkit-transform: matrix(1.048,0,0,1.048,0,0);
                transform: matrix(1.048,0,0,1.048,0,0)
    }

    35.54% {
        -webkit-transform: matrix(.979,0,0,.979,0,0);
                transform: matrix(.979,0,0,.979,0,0)
    }

    41.04% {
        -webkit-transform: matrix(.961,0,0,.961,0,0);
                transform: matrix(.961,0,0,.961,0,0)
    }

    52.15% {
        -webkit-transform: matrix(.991,0,0,.991,0,0);
                transform: matrix(.991,0,0,.991,0,0)
    }

    63.26% {
        -webkit-transform: matrix(1.007,0,0,1.007,0,0);
                transform: matrix(1.007,0,0,1.007,0,0)
    }

    85.49% {
        -webkit-transform: matrix(.999,0,0,.999,0,0);
                transform: matrix(.999,0,0,.999,0,0)
    }

    to {
        -webkit-transform: matrix(1,0,0,1,0,0);
                transform: matrix(1,0,0,1,0,0)
    }
}

@-webkit-keyframes cssload-dot {
    0% {
        -webkit-transform: scaleY(.7);
                transform: scaleY(.7)
    }

    20% {
        -webkit-transform: scale(.7,1.2);
                transform: scale(.7,1.2)
    }

    40% {
        -webkit-transform: scale(1);
                transform: scale(1)
    }

    50% {
        bottom: 97px
    }

    46% {
        -webkit-transform: scale(1);
                transform: scale(1)
    }

    80% {
        -webkit-transform: scale(.7,1.2);
                transform: scale(.7,1.2)
    }

    90% {
        -webkit-transform: scale(.7,1.2);
                transform: scale(.7,1.2)
    }

    to {
        -webkit-transform: scaleY(.7);
                transform: scaleY(.7)
    }
}

@keyframes cssload-dot {
    0% {
        -webkit-transform: scaleY(.7);
                transform: scaleY(.7)
    }

    20% {
        -webkit-transform: scale(.7,1.2);
                transform: scale(.7,1.2)
    }

    40% {
        -webkit-transform: scale(1);
                transform: scale(1)
    }

    50% {
        bottom: 97px
    }

    46% {
        -webkit-transform: scale(1);
                transform: scale(1)
    }

    80% {
        -webkit-transform: scale(.7,1.2);
                transform: scale(.7,1.2)
    }

    90% {
        -webkit-transform: scale(.7,1.2);
                transform: scale(.7,1.2)
    }

    to {
        -webkit-transform: scaleY(.7);
                transform: scaleY(.7)
    }
}

@-webkit-keyframes cssload-anim {
    0% {
        opacity: 0;
        top: 0;
        right: 0
    }

    50% {
        opacity: 1
    }

    to {
        top: 88px;
        right: 88px;
        opacity: 0
    }
}

@keyframes cssload-anim {
    0% {
        opacity: 0;
        top: 0;
        right: 0
    }

    50% {
        opacity: 1
    }

    to {
        top: 88px;
        right: 88px;
        opacity: 0
    }
}

.cssload-s1 {
    -webkit-animation: cssload-anim 2.07s linear infinite;
            animation: cssload-anim 2.07s linear infinite;
}
.stepX {
    position: absolute;
    width: 36px;
    height: 36px;
    border-top: 4px solid #fcd535;
    top: 0;
    right: 0;
}
.cssload-s2 {
    -webkit-animation: cssload-anim 2.07s linear -.69s infinite;
            animation: cssload-anim 2.07s linear -.69s infinite;
}
.cssload-s3 {
    -webkit-animation: cssload-anim 2.07s linear -1.38s infinite;
            animation: cssload-anim 2.07s linear -1.38s infinite;
}
.moderator-block .text-danger {
    margin: 20px auto;
    display: block;
}
.message-load {
    display: block;
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.8rem;
    margin-bottom: 15px;
    font-weight: bold;

}

.message-load:after {
    content: ' .';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;}
  
  @-webkit-keyframes dots {
    0%, 20% {
      color: white;
      text-shadow:
        .25em 0 0 white,
        .5em 0 0 white;}
    40% {
      color: black;
      text-shadow:
        .25em 0 0 white,
        .5em 0 0 white;}
    60% {
      text-shadow:
        .25em 0 0 black,
        .5em 0 0 white;}
    80%, 100% {
      text-shadow:
        .25em 0 0 black,
        .5em 0 0 black;}}
  
  @keyframes dots {
    0%, 20% {
      color: white;
      text-shadow:
        .25em 0 0 white,
        .5em 0 0 white;}
    40% {
      color: black;
      text-shadow:
        .25em 0 0 white,
        .5em 0 0 white;}
    60% {
      text-shadow:
        .25em 0 0 black,
        .5em 0 0 white;}
    80%, 100% {
      text-shadow:
        .25em 0 0 black,
        .5em 0 0 black;}}
  
        @-webkit-keyframes dots1 {
            0%, 20% {
              color: fcd535;
              text-shadow:
                .25em 0 0 fcd535,
                .5em 0 0 fcd535;}
            40% {
              color: black;
              text-shadow:
                .25em 0 0 fcd535,
                .5em 0 0 fcd535;}
            60% {
              text-shadow:
                .25em 0 0 black,
                .5em 0 0 fcd535;}
            80%, 100% {
              text-shadow:
                .25em 0 0 black,
                .5em 0 0 black;}}
  
        @keyframes dots1 {
            0%, 20% {
              color: fcd535;
              text-shadow:
                .25em 0 0 fcd535,
                .5em 0 0 fcd535;}
            40% {
              color: black;
              text-shadow:
                .25em 0 0 fcd535,
                .5em 0 0 fcd535;}
            60% {
              text-shadow:
                .25em 0 0 black,
                .5em 0 0 fcd535;}
            80%, 100% {
              text-shadow:
                .25em 0 0 black,
                .5em 0 0 black;}}
.chatBox {
    padding: 0 0 40px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    display: none;
}
.chatBox-inner {
    -webkit-transition: -webkit-transform .5s ease;
    transition: -webkit-transform .5s ease;
    -o-transition: transform .5s ease;
    transition: transform .5s ease;
    transition: transform .5s ease, -webkit-transform .5s ease;
    -webkit-transition-delay: .5;
         -o-transition-delay: .5;
            transition-delay: .5;
    -webkit-transform: scale(0);
        -ms-transform: scale(0);
            transform: scale(0);
}
.chatBox.visible .chatBox-inner {
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
}
.chatBox.visible {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.chatBox-inner {
    width: 320px;
    height: 95vh;
    max-height: 590px;
    z-index: 2;
    overflow: hidden;
    -webkit-box-shadow: 0 5px 30px rgb(0 0 0 / 20%);
            box-shadow: 0 5px 30px rgb(0 0 0 / 20%);
    border-radius: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.chatBox-header {
    padding: 20px 15px;
    background: #fcd535;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    
}
.chatBox-header img {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    margin-right: 10px;
}
.chatBox-header .img {
    position: relative;
}
.chatBox-header .img::after {
    content: "";
    position: absolute;
    top: 0;
    right: 9px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #40BE61;
}
.chatBox-header small {
    font-size: 10px;
    line-height: 14px;
}

.chatBox-content {
    background-color: #fff!important;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    color: #000;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    width: 100%;
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.chatbox-content .message-load {
    font-size: 1rem;
    text-align: start;
    margin-bottom: 0;
    height: 20px;
    font-weight: normal;
    line-height: 1.5rem;
}

.chatbox-content .message-load:after {
    content: ' .';
    -webkit-animation: dots1 1s steps(5, end) infinite;
            animation: dots1 1s steps(5, end) infinite;
}
.message .btn-default {
    padding: 6px 12px;
    cursor: pointer;
}
.chatBox-content .message.new {
    -webkit-transform: scale(0);
        -ms-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-animation: bounce .5s linear both;
            animation: bounce .5s linear both;
}
.chatBox-content .message {
    clear: both;
    float: left;
    padding: 6px 10px 7px;
    border-radius: 10px 10px 10px 0;
    margin: 8px 0;
    font-size: 15px;
    line-height: 1.4;
    margin-left: auto;
    position: relative;
    text-shadow: 0 1px 1px rgb(0 0 0 / 20%);
    max-width: calc(100% - 60px);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.chatBox-content .message img {
    position: absolute;
    z-index: 1;
    bottom: -20px;
    left: -58px;
    border-radius: 30px;
    width: 48px;
    height: 48px;
    overflow: hidden;
    margin: 0;
    padding: 0;
    border: 2px solid hsla(0,0%,100%,.24);
}

.text-dark {
    color: #18344e!important;
}
.shadow {
    -webkit-box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
            box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}
.answerMsgBtn {
    margin-top: 10px;
    clear: both;
    float: right!important;
    padding: 6px 10px 7px;
    border-radius: 10px 10px 0px 10px !important;
    line-height: 1.4;
    position: relative;
    text-shadow: 0 1px 1px rgb(0 0 0 / 20%);
    width: 100%;
}
.answerMsgBtn > span {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 10px 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    max-width: 175px;
    margin-left: auto;
}
.answerMsgBtn .btn:last-child {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / 3;
}
.fixed-block {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
    background: rgba(0,0,0,.6);
    color: #fff;
    z-index: 100;
}

@media (max-width: 768px) {
    .moderator-block {
        padding-top: 20px;
        margin-top: 10px;
    }
}