.authWrapper {
    width: 100%;
    height: 100vh;
}
.adminAuth {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.authWrapper .login-content{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	text-align: center;
}


.authWrapper form{
	width: 360px;
}

.authWrapper .login-content img{
    height: 100px;
}

.authWrapper .login-content h2{
	margin: 15px 0;
	color: #333;
	text-transform: uppercase;
	font-size: 2.9rem;
}

.authWrapper .login-content .input-div{
	position: relative;
    display: grid;
    grid-template-columns: 7% 93%;
    margin: 25px 0;
    padding: 5px 0;
    border-bottom: 2px solid #d9d9d9;
}

.authWrapper .login-content .input-div.one{
	margin-top: 0;
}

.authWrapper .i{
	color: #d9d9d9;
	display: flex;
	justify-content: center;
	align-items: center;
}

.authWrapper .i i{
	transition: .3s;
}

.authWrapper .input-div > div{
    position: relative;
	height: 45px;
}


.authWrapper .input-div:before, .input-div:after{
	content: '';
	position: absolute;
	bottom: -2px;
	width: 0%;
	height: 2px;
	background-color: #38d39f;
	transition: .4s;
}

.authWrapper .input-div:before{
	right: 50%;
}

.authWrapper .input-div:after{
	left: 50%;
}

.authWrapper .input-div.focus:before, .input-div.focus:after{
	width: 50%;
}

.authWrapper .input-div.focus > div > h5{
	top: -5px;
	font-size: 15px;
}

.authWrapper .input-div.focus > .i > i{
	color: #38d39f;
}

.authWrapper .input-div > div > input{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border: none;
	outline: none;
	background: none;
	padding: 0.5rem 0.7rem;
	font-size: 1.2rem;
	color: #555;
	font-family: 'poppins', sans-serif;
}

.authWrapper .input-div.pass{
	margin-bottom: 4px;
}

.authWrapper a{
	display: block;
	text-align: center;
	text-decoration: none;
	color: #999;
	font-size: 0.9rem;
	transition: .3s;
}

.authWrapper a:hover{
	color: #38d39f;
}

.authWrapper .btn{
	display: block;
	width: 100%;
	height: 50px;
	border-radius: 25px;
	outline: none;
	border: none;
	background-image: linear-gradient(to right, #32be8f, #38d39f, #32be8f);
	background-size: 200%;
	font-size: 1.2rem;
	color: #fff;
	font-family: 'Poppins', sans-serif;
	text-transform: uppercase;
	margin: 1rem auto;
	cursor: pointer;
	transition: 777ms;
}
.authWrapper .btn:hover{
	background-position: right;
	display: block;
	background-image: linear-gradient(to left, aqua, #38d39f, lightcyan);
	transform: scale(104%);
}
.authWrapper .error {
    margin-top: 20px;
    font-size: 1em;
    line-height: 1em;
    color: red;
}