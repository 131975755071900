
.progress-bar {
    background-color: #2ab561;
    background-image: -o-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    background-size: 1rem 1rem;
    -webkit-animation: 1s linear infinite progress-bar-stripes;
            animation: 1s linear infinite progress-bar-stripes;
}
.loader {
    margin-top: 30px;
    min-height: 450px;
    -webkit-transition: -webkit-transform .5s ease;
    transition: -webkit-transform .5s ease;
    -o-transition: transform .5s ease;
    transition: transform .5s ease;
    transition: transform .5s ease, -webkit-transform .5s ease;
}
.loader.invisible {
    -webkit-transform: scale(0);
        -ms-transform: scale(0);
            transform: scale(0);
}
.load-result .h2 {
    font-size: 2rem;
    line-height: 1.5em;
    font-weight: bold;
    margin-bottom: 10px;
}
.load-result {
    margin-top: 40px;
    -webkit-transform: scale(0);
        -ms-transform: scale(0);
            transform: scale(0);
    -webkit-transition: -webkit-transform .5s ease;
    transition: -webkit-transform .5s ease;
    -o-transition: transform .5s ease;
    transition: transform .5s ease;
    transition: transform .5s ease, -webkit-transform .5s ease;
    border-radius: 10px;
    border: 1px solid #dee2e6;
    padding: 50px;
}
.load-result.visible {
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
}
.load-progress {
    height: 20px;
    overflow: hidden;
    font-size: 1.2rem;
    background-color: #e9ecef;
    border-radius: 0.4rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: white;
    text-align: center;
}
.load-progress small {
    padding-left: 10px;
}
.loader .table>:not(caption)>*>* {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    -webkit-box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
            box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.loader tr {
    border-color: #dee2e6;
}
.text-hash {
    color: #0c6cf2;
}
.bg-success-highlight {
    background-color: #dcffd3!important;
}
.bg-danger-highlight {
    background-color: #ffdfdf!important;
}
.text-danger {
    color: rgba(220,53,69,1);
    font-weight: bold;
    text-transform: uppercase;
}
.text-success {
    color: rgba(25,135,84,1);
    font-weight: bold;
    text-transform: uppercase;
}

.text-maximum {
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 150px;
}
.text-center {
    text-align: center;
}
@-webkit-keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem;
    }
}
@keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem;
    }
}
.lr-res {
    background: #f0fff1;
    color: #2ab561;
    font-weight: 800;
    padding: 0 10px;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.5em;
    line-height: 1.8em;
}
.botblock {
    margin-top: 30px;
}
.h3 {
    font-size: 1.5rem;
    line-height: 1.8rem;
    margin-bottom: 20px;
    color: #2ab561;
}
.h5 {
    font-size: 1em;
}
.bg-text {
    background: #f0fff1;
    color: #2ab561;
    font-size: 1rem;
    font-weight: 800;
    padding: 0 10px;
    line-height: 1.8em;
    text-align: center;
    margin-top: 20px;
    display: block;
}
.botblock .btn {
    min-width: 200px;
}
.table>thead {
    vertical-align: bottom;
}
.text-white {
    color: white;
}
.bg-success, .header-bg, .hero-bg {
    background: -webkit-gradient(linear,right top, left top,color-stop(.35%, #2ab561),color-stop(150.02%, #8cdb61));
    background: -o-linear-gradient(right,#2ab561 .35%,#8cdb61 150.02%);
    background: linear-gradient(-90deg,#2ab561 .35%,#8cdb61 150.02%);
}
.text-success-highlight span, .text-success-highlight-full {
    background: #f0fff1;
    color: #2ab561;
}
.text-attention-highlight-full {
    color: rgb(230, 95, 110);
}
.table-result>:not(caption)>*>* {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    -webkit-box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
            box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table-result tr {
    border-color: #dee2e6;
}

@media (max-width: 768px) {
    .load-result {
        padding: 20px 15px;
    }
    .loader .table {
        font-size: 0.6em;
    }
    .loader .table>:not(caption)>*>* {
        padding: 0.2rem 0.2rem;
    }
    .load-progress small {
        font-size: 0.5em;
    }
    .load-result .h2 {
        font-size: 1.5rem;
    }
}