.notification-block {
  position: fixed;
  bottom: 10px;
  left: 10px;
  padding: 10px 20px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  background: white;
  width: 230px;
  font-weight: 500;
}
.notification-block .row {
  display: flex;
  align-items: center;
}
.notification-block .row img {
  margin-right: 5px;
}
.notification-block .row i {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
.notification-block p {
  font-size: 0.8em;
  margin-top: 5px;
}

.notification-block.fade-in-out {
  transition: opacity 0.3s linear;
}

.notification-block.fade-in {
  opacity: 1;
}

.notification-block.fade-out {
  opacity: 0;
}
