.proposal-inner {
    padding: 20px 50px;
    border: 1px solid #dee2e6;
    border-radius: 10px;
}
.success-block {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    background: #D1E7DD;
    color: #0f5132;
}
.proposal-form {
    margin-top: 20px;
    max-width: 600px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.mainForm {
    font-size: 0.89rem;
    margin-top: 30px;
}
.proposal-title {
    font-size: 1.6rem;
    line-height: 1.6rem;
    margin-bottom: 20px;
    font-weight: bold;
}
.p-info {
    font-size: 1.7rem;
    line-height: 1.8rem;
}
.green {
    color: rgb(42, 182, 42);
    margin: 1rem 0;
    font-size: 2rem;
    font-weight: bold;
}
.todo-p {
    font-size: 0.8em;
    margin-bottom: 1rem;
    font-weight: bold;
}
.prop-img {
    opacity: 0.3;
    width: 100px;
    margin: 20px auto 0;
}
label {
    display: block;
    margin-bottom: 5px;
}
.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-item:not(:last-child) {
    margin-bottom: 15px;
}
.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.mainForm .btn {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    max-width: 350px;
    text-transform: uppercase;
    background: #fcd535;
    color: #212529;
}
.mainForm .btn:disabled {
    background: #6c757d;
    pointer-events: none;
    color: white;
    opacity: 0.645;
}
.text-result {
    font-size: 1rem;
    margin-top: 15px;
    margin-bottom: 10px;
}
.text-result .text {
    margin-bottom: 10px;
}
.fa-circle-check {
    color: #198754;
}

@media (max-width: 768px) {
    .proposal-inner {
        padding: 17px;
    }
    .p-info {
        font-size: 1.2rem;
        line-height: 1.3rem;
    }
    .mainForm .btn {
        font-size: 13px;
    }
}