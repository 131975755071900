

.loginForm {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.loginForm__inner {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    -webkit-box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
            box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    background: white;
    margin: 50px auto 0;
    padding: 30px;
    min-width: 500px;
}

@media (min-width: 1200px) {
    .loginForm__inner {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 33%;
                flex: 0 0 33%;
    }
}
.btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 38px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    width: 100%;
    max-width: 200px;
    border-radius: 0.375rem;
}
.loginForm__inner .name {
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
    margin-bottom: 15px;
}
.loginForm__inner .logo {
    text-align: center;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.loginForm__inner form {
    font-size: 0.8em;
    margin: 10px 0;
}
.loginForm__inner .fa-solid {
    font-size: 3em;
    text-align: center;
    color: #FCD535;
}
.loginForm__inner label {
    display: block;
    margin-bottom: 5px;
}
.loginForm__inner input {
    width: 100%;
    height: 38px;
    background-color: #e9ecef;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}
.input-wrapper {
    margin-bottom: 10px;
}
.loginForm__inner .btn {
    margin: 0 auto 10px;
}
.or {
    text-align: center;
    font-size: 1.5em;
    margin: 20px 0;
}
.btn-default {
    background: #FCD535;
}
.btn-outline {
    background: white;
    border: 1px solid #FCD535;
    border-radius: 0.375rem;
}
.google-in {
    border: 1px solid #0d6efd;
    color: #0d6efd;
}
.google-in img {
    margin-right: 5px;
}
.logIn {
    margin-top: 30px !important;
    -webkit-animation: animate 3s linear infinite;
            animation: animate 3s linear infinite;
}

.push {
    padding: 20px;
    background: rgba(243, 201, 15, 0.557);
    color: rgb(132, 108, 0);
    text-align: center;
    font-size: 1.21em;
    position: relative;
}
.push .fa-solid {
    font-size: 1em;
    color: rgb(132, 108, 0);
}
.push .fa-xmark {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    font-size: 1.5em;
}
.google-push {
    background: #f1656582;
    color: rgb(175, 54, 54);
    text-transform: uppercase;
    font-weight: 500;
}
.google-push .fa-xmark {
    color: rgb(175, 54, 54);
}
@-webkit-keyframes animate {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 208, 0, 0.7), 0 0 0 0 rgba(255, 208, 0, 0.7);
                box-shadow: 0 0 0 0 rgba(255, 208, 0, 0.7), 0 0 0 0 rgba(255, 208, 0, 0.7);
    }
    40% {
        -webkit-box-shadow: 0 0 0 20px rgba(255, 208, 0, 0), 0 0 0 0 rgba(255, 208, 0, 0.7);
                box-shadow: 0 0 0 20px rgba(255, 208, 0, 0), 0 0 0 0 rgba(255, 208, 0, 0.7);
    }
    80% {
        -webkit-box-shadow: 0 0 0 20px rgba(255, 208, 0, 0), 0 0 0 10px rgba(255, 208, 0, 0);
                box-shadow: 0 0 0 20px rgba(255, 208, 0, 0), 0 0 0 10px rgba(255, 208, 0, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 208, 0, 0), 0 0 0 10px rgba(255, 208, 0, 0);
                box-shadow: 0 0 0 0 rgba(255, 208, 0, 0), 0 0 0 10px rgba(255, 208, 0, 0);
    }
}
@keyframes animate {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 208, 0, 0.7), 0 0 0 0 rgba(255, 208, 0, 0.7);
                box-shadow: 0 0 0 0 rgba(255, 208, 0, 0.7), 0 0 0 0 rgba(255, 208, 0, 0.7);
    }
    40% {
        -webkit-box-shadow: 0 0 0 20px rgba(255, 208, 0, 0), 0 0 0 0 rgba(255, 208, 0, 0.7);
                box-shadow: 0 0 0 20px rgba(255, 208, 0, 0), 0 0 0 0 rgba(255, 208, 0, 0.7);
    }
    80% {
        -webkit-box-shadow: 0 0 0 20px rgba(255, 208, 0, 0), 0 0 0 10px rgba(255, 208, 0, 0);
                box-shadow: 0 0 0 20px rgba(255, 208, 0, 0), 0 0 0 10px rgba(255, 208, 0, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 208, 0, 0), 0 0 0 10px rgba(255, 208, 0, 0);
                box-shadow: 0 0 0 0 rgba(255, 208, 0, 0), 0 0 0 10px rgba(255, 208, 0, 0);
    }
}

@media (max-width: 768px) {
    .loginForm__inner {
        margin: 20px auto 0;
        min-width: 450px;
    }
}

@media (max-width: 475px) {
    .loginForm__inner {
        margin: 20px 20px 0;
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1;
        min-width: unset;
    }
}