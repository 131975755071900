
.header {
  background-color: #fcd535;
  /* background-color: rgb(255, 200, 0); */
}
.header__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 85px;
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.header__logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1.25em;
  font-weight: bold;
  text-transform: uppercase;
}
.header__logo img {
  margin-right: 10px;
}
.header__greet {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: bold;
}
.header__greet .text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.header__greet .login-name {
  padding-left: 10px;
}
.header__greet .online {
  padding: 0 20px;
  color: rgb(42, 182, 42);
}
.header__wallet {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.header__wallet .top span {
  font-size: 2em;
  padding-right: 5px;
}
.header__wallet i {
  font-size: 1.2em;
}
.header__wallet .top {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 0.8em;
  color: rgb(42, 182, 42);
}

.scaleIn {
  display: inline-block;
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@media (max-width: 1200px) {
  .header__greet {
    display: block;
  }
  .header__greet .online {
    padding: 10px 0 0;
  }
  .header__inner {
    height: unset;
    padding: 20px;
  }
}

@media (max-width: 991px) {
  body {
    font-size: 14px;
    line-height: 16px;
  }
  .header__greet .online {
    padding-top: 6px;
  }
}

@media (max-width: 768px) {
  .header__inner {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 10px 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  .header__logo {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / 2;
  }
  .header__wallet {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2 / 3;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: end;
    text-align: end;
  }
  .header__greet {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 / 3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / 3;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .header {
    font-size: 0.9em;
  }
  .header__logo img {
    width: 30px;
    height: auto;
  }
  .header__greet .online {
    padding: 0 10px;
  }
}

@media (max-width: 470px) {
  .header__inner {
    -ms-grid-columns: 1fr 10px 2fr;
    grid-template-columns: 1fr 2fr;
  }
  .header__wallet p {
    font-size: 12px;
  }
  .header__logo img {
    margin-right: 5px;
  }
}