.show-tablet {
    display: none;
  }
  @media only screen and (max-width: 991px) {
    .show-tablet {
      display: block;
    }
  }
  .hide-tablet {
    display: block;
  }
  @media only screen and (max-width: 991px) {
    .hide-tablet {
      display: none;
    }
  }
  .show-mob {
    display: none;
  }
  @media only screen and (max-width: 767px) {
    .show-mob {
      display: block;
    }
  }
  .hide-mob {
    display: block;
  }
  @media only screen and (max-width: 767px) {
    .hide-mob {
      display: none;
    }
  }
  .transition {
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
  }
  body,
  html {
    overflow-x: hidden;
  }
  body {
    font-size: 16px !important;
    font-family: Inter, sans-serif !important;
    background: #fff !important;
    overflow-x: hidden;
    color: #000 !important;
    line-height: 1.4 !important;
  }
  .container-fluid {
    max-width: 1194px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
  .frame {
    width: 100vw;
    height: 100vh;
    padding-top: 5px;
  }
  @media only screen and (max-width: 767px) {
    .frame {
      width: auto;
      height: auto;
    }
  }
  .frame-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    border-top: 1px solid #e1e1e1;
  }
  @media only screen and (max-width: 767px) {
    .frame-inner {
      display: block;
    }
  }
  .frame-left {
    width: 50%;
    border-right: 1px solid #e1e1e1;
    padding: 32px;
  }
  @media only screen and (max-width: 1200px) {
    .frame-left {
      width: 35%;
    }
  }
  @media only screen and (max-width: 767px) {
    .frame-left {
      width: auto;
      border-right: none;
      border-bottom: 1px solid #e1e1e1;
    }
  }
  .frame-left-inner {
    height: 100%;
    position: relative;
  }
  .frame-left-title {
    font-size: 24px;
    font-weight: 500;
  }
  .frame-left-bottom {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  @media only screen and (max-width: 767px) {
    .frame-left-bottom {
      position: static;
    }
  }
  .frame-left-bottom-title {
    color: #6b92b3;
    font-size: 72px;
    line-height: 1.2;
  }
  @media only screen and (max-width: 1200px) {
    .frame-left-bottom-title {
      font-size: 48px;
    }
  }
  @media only screen and (max-width: 767px) {
    .frame-left-bottom-title {
      font-size: 48px;
    }
  }
  .frame-left-bottom-subtitle {
    color: #555;
  }
  .frame-right {
    padding: 34px 24px;
    width: 50%;
  }
  @media only screen and (max-width: 1200px) {
    .frame-right {
      width: 65%;
    }
  }
  @media only screen and (max-width: 767px) {
    .frame-right {
      width: auto;
      padding: 15px;
      padding-top: 24px;
    }
  }
  .frame-right-block {
    width: 100%;
    padding: 24px;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    margin-bottom: 24px;
  }
  .inv-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .inv-block-icon {
    width: 80px;
    height: 80px;
    -webkit-box-flex: 80px;
    -ms-flex: 80px;
    flex: 80px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    background: #fafafa;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 32px;
    position: relative;
  }
  .inv-block-icon img {
    width: 50px;
    height: auto;
    opacity: 0.35;
  }
  .inv-block-plus {
    width: 30px;
    height: 30px;
    background: rgba(0, 0, 0, 0.15);
    position: absolute;
    right: 15px;
    bottom: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    border-radius: 100%;
  }
  .inv-block-title {
    font-weight: bold;
  }
  .inv-block-subtitle {
    color: #555;
    margin-top: 5px;
  }
  .payblock-method {
    margin-left: -24px;
    margin-right: -24px;
    margin-top: -24px;
    padding: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid #e1e1e1;
  }
  .payblock-method-title {
    font-size: 20px;
    font-weight: bold;
  }
  .payblock-method-subtitle {
    color: #555;
    font-size: 15px;
  }
  .payblock-method-time {
    width: 56px;
    height: 56px;
    -webkit-box-flex: 56px;
    -ms-flex: 56px;
    flex: 56px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    border: 3px solid #61646b;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #555;
    border-radius: 100%;
    font-size: 14px;
    margin-left: 15px;
  }
  .payblock-total {
    margin-top: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .payblock-total-title {
    font-size: 20px;
    font-weight: bold;
  }
  .payblock-total-price {
    font-size: 20px;
    font-weight: bold;
  }
  .payblock-checkout {
    margin-top: 64px;
  }
  @media only screen and (max-width: 767px) {
    .payblock-checkout {
      margin-top: 32px;
    }
  }
  .payblock-checkout-paybutton {
    margin-top: 15px;
    width: 100%;
    height: 42px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    background: #1652f0;
    border-radius: 60px;
    cursor: pointer;
  }
  .payblock-checkout-paybutton:hover {
    background: #0d40c6;
  }
  .payblock-or {
    margin: 20px 0 0px 0;
  }
  .payblock-cryptos {
    margin-top: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-left: -5px;
    margin-right: -5px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  @media only screen and (max-width: 767px) {
    .payblock-cryptos {
      display: block;
    }
  }
  .payblock-cryptos-col {
    padding: 0 5px;
    width: 25%;
    margin-top: 10px;
  }
  @media only screen and (max-width: 1200px) {
    .payblock-cryptos-col {
      width: 33.33333333%;
    }
  }
  @media only screen and (max-width: 767px) {
    .payblock-cryptos-col {
      width: auto;
    }
  }
  .payblock-cryptos-item {
    color: #333 !important;
    text-decoration: none !important;
    background: #eff0f4;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-radius: 60px;
    height: 42px;
    padding: 10px;
    font-size: 15px;
    cursor: pointer;
  }
  .payblock-cryptos-item:hover {
    background: #d1d4df;
  }
  .payblock-cryptos-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .payblock-cryptos-icon {
    margin-right: 10px;
  }
  .payblock-cryptos-icon img {
    width: 26px;
    height: auto;
  }
  .payblock-cryptos-name {
    color: #777;
    font-size: 14px;
  }
  .payblock-new {
    margin: 24px 0 14px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .payblock-new-icon {
    height: 22px;
    background: #1652f0;
    width: 52px;
    -webkit-box-flex: 52px;
    -ms-flex: 52px;
    flex: 52px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    border-radius: 30px;
    font-size: 12px;
    line-height: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 10px;
    color: #fff;
    font-weight: bold;
  }
  .payblock-new-title {
    font-weight: bold;
  }
  .payblock-new-info {
    margin-left: 10px;
    width: 16px;
    -webkit-box-flex: 16px;
    -ms-flex: 16px;
    flex: 16px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    height: 16px;
    background: #646776;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    border-radius: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .payblock-qr {
    margin-top: 32px;
    text-align: center;
    padding-bottom: 24px;
    position: relative;
  }
  .payblock-pic {
    position: relative;
  }
  .qr-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .qr-icon img {
    width: 32px;
    height: auto;
  }
  .payblock-address {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 24px;
    border-top: 1px solid #e1e1e1;
    margin-left: -24px;
    margin-right: -24px;
  }
  .payblock-address:last-child {
    padding-bottom: 0;
  }
  .payblock-address-content {
    font-size: 14px;
    color: #555;
  }
  .payblock-address-content b {
    font-weight: normal;
    font-size: 16px;
    display: block;
    margin-top: 5px;
    color: #000;
  }
  .payblock-address-content i {
    font-style: normal;
  }
  .payblock-address-copy {
    font-weight: bold;
    color: #1652f0;
    margin-left: 24px;
    cursor: pointer;
  }
  .payblock-address-copy:hover {
    color: #000;
  }
  .payblock-back-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-top: 24px;
    align-items: center;
  }
  .payblock-back {
    width: 100px;
    background: #eff0f4;
    border-radius: 60px;
    height: 42px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
  }
  .payblock-back:hover {
    background: #d1d4df;
  }
  .payblock-credit {
    margin-top: 60px;
    font-size: 14px;
    text-align: center;
    color: #555;
  }
  .payblock-credit a {
    font-weight: bold;
    text-decoration: none;
    color: #1652f0;
  }
  .payblock-credit a:hover {
    text-decoration: underline;
  }
  .mt0 {
    margin-top: 0;
  }
  * {
    outline: none !important;
  }
  .bright:hover {
    -webkit-filter: brightness(1.1);
    filter: brightness(1.1);
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    opacity: 1 !important;
  }
  ::-webkit-input-placeholder {
    color: #686868;
    opacity: 1;
  }
  :-moz-placeholder {
    color: #686868;
    opacity: 1;
  }
  ::-moz-placeholder {
    color: #686868;
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: #686868;
    opacity: 1;
  }
  

  .payblock-pic img {
    max-width: unset;
    display: unset;
  }
  .smile-block {
    text-align: center;
    padding: 30px 0;
  }
  .smile-block span {
    margin: 30px 0;
    font-size: 140px;
    line-height: 145px;
    text-align: center;
  }
  .smile-block p {
    margin-top: 20px;
  }
  .sup-connect {
    max-width: 300px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: auto;
    margin-left: auto;
  }

  @media (max-width: 600px) {
    .frame-right-block {
        padding: 16px;
    }
    body {
        font-size: 14px;
    }
    .frame-left-bottom-title {
        font-size: 30px;
    }
    .payblock-address {
        flex-direction: column;
        align-items: start;
    }
    .payblock-address-content b {
        font-size: 13px;
    }
    .payblock-address-copy {
        margin: 10px 0 0;
    }
    .payblock-back-wrap {
        align-items: center;
        justify-content: center;
    }
  }

  .pleaseForm {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 24%);
    z-index: 10;
  }
  .pleaseInner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .cross {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-weight: bold;
  }
  .pleaseInner p {
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 15px;
    text-align: center;
    font-weight: bold;
  }
  .pleaseInner input {
    height: 50px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #fcd535;
    margin-bottom: 15px;
    padding: 10px;
  }
  .pleaseInner .btn {
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
  }