.footer-container {
    background-color: #FCD535;
}
.footer {
    background: #fff;
}
.footer-inner {
    display: flex;
    align-items: center;
    height: 85px;
    max-width: 1500px;
    width: 100%;
    margin: 0 auto;
    padding: 0 30px;
    justify-content: space-between;
}
.footer__logo {
    display: flex;
    align-items: center;
    font-size: 1.25em;
    font-weight: bold;
    text-transform: uppercase;
}
.footer__logo img {
    margin-right: 10px;
}
.socnets {
    display: flex;
    align-items: center;
}
.socnets > span {
    display: flex;
    align-items: center;
    margin-right: 9px;
}
.socnets i {
    margin-right: 4px;
}
.rights {
    text-align: center;
    padding: 20px;
}
.rights a {
    color: blue;
    text-decoration: underline;
    font-weight: bold;
}
.rights > * {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

@media (max-width: 568px) {
    .footer-inner {
        display: block;
    }
}