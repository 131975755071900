.list__item {
    position: relative;
    display: inline-block;
    animation-play-state: running;
    background: white;
    font-size: 0.8em;
    line-height: 15px;
    padding: 0 10px;
}
.list__item::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 30%;
    width: 1px;
    background: rgb(185, 182, 182);
}
.list__item_inner {
    position: relative;
    min-width: 220px;
    display: inline-block;
    box-sizing: border-box;
    padding: 0 16px
}
.list__item_content {
    padding: 16px 0;
    display: flex;
    width: 100%;
    align-items: center;
    text-decoration: none;
    color: #000;
}
.list__item_content img {
    margin-right: 10px;
}
.list__item_content .text p:first-child {
    font-weight: bold;
}
.list__item_content .value {
    text-align: end;
    margin-left: auto;
    font-weight: bold;
   
    font-size: 0.8em;
}

.list__item_content  .change.low {
    color: #ea3943;
}
.list__item_content  .change .arr {
    transform: rotate(180deg);
    display: inline-block; 

}
.list__item_content  .change {
    color: #53c018;
}
