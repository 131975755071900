@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;900&amp;display=swap');


#root, .App {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 18px;
  line-height: 20px;
  overflow-x: hidden;
  background: white;
  padding-right: 0px;
}
.whited {
  background-repeat: repeat-x;
  background-position: left center;
  background-size: contain;
  background-image: url(img/bg.png);
  background-attachment: fixed;
  min-height: 100vh;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}


ul[class],
ol[class] {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

html, body {
  height: 100%;
}


ul[class],
ol[class] {
  list-style: none;
}

a {
  text-decoration-skip-ink: auto;
  text-decoration: none;
}

img {
  max-width: 100%;
  display: block;
}

button {
  padding: 0;
  background-color: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}
input {
  outline: none;
  border: 0;
  padding: 0;
  background: transparent;
  border: none;
}

input,
button,
textarea,
select {
  font: inherit;
}
a {
  color: inherit;
  text-decoration: unset;
}
ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}