.timerRow {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 300px;
    font-size: 2em;
    line-height: 1em;
    font-weight: bold;
    margin: 40px auto;
    text-align: center;
}
.timerRow div span {
    font-size: 0.4em;
    display: block;
    padding-top: 5px;
}
.timerRow div {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
}
.timerRow div:not(:last-child)::after {
    content: ":";
    position: absolute;
    top: 2px;
    right: -5px;
    font-size: 0.5em;
}