.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 4;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.5s, opacity 0.5s linear;
  overflow-x: hidden;
}
.popup.open {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.popup.close {
  visibility: visible;
  opacity: 0;
  transition-delay: 0s;
}
.layout {
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.popupsInner {
  z-index: 6;
  max-width: 1200px;
  width: 100%;
  border-radius: 10px;
  background: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
}
.popupsInner h2 {
  font-size: 2em;
  line-height: 1.5em;
  margin: 20px auto 0;
  text-align: center;
}
.popupHeader {
  background: #fcd535;
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 50px;
  flex: 1;
}
.popupHeader img {
  width: 190px;
  height: 190px;
  margin-bottom: -70px;
}
.step {
  background: #fff;
  padding: 30px 57px;
  text-align: center;
}

.warning {
    font-size: 1.5em;
    line-height: 1.5em;
    color: #dc3545;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 1em;
}
.step .text {
    text-align: start;
    margin-bottom: 20px;
}

.step  .change.low {
    color: #ea3943;
}
.step  .change .arr {
    transform: rotate(180deg);
    display: inline-block; 

}
.step  .change {
    color: #53c018;
}
.balance-block {
    box-shadow: inset 0 -6px 16px 0 #fff;
    border: 1px solid #cacaca;
    border-radius: 10px;
    background-image: linear-gradient(44deg,#fae421,hsla(0,0%,100%,0) 120%);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 500;
    margin-bottom: 50px;
    margin-top: 20px;
}
.balance-block .price {
    font-size: 2em;
    line-height: 1.5em;
    font-weight: bold;
}
.balance-block .value-btc {
    font-size: 0.9em;
    color: #716f6f;
    margin-top: 5px;
    font-weight: bold;
}
.btn-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
}
.step .btn {
    margin-left: auto;
    margin-right: auto;
    height: 55px;
    font-weight: bold;
    text-transform: uppercase;
    animation: animate 3s linear infinite;
    position: relative;
    margin: 20px 0;
}
.step hr {
    background: #dbd5d5;
}
.step {
    flex: 1;
    font-weight: 500;
    line-height: 1.4em;
}
.step .ul li {
    padding: 0 24px;
    border-right: 1px solid #dc3545;
    border-left: 1px solid #dc3545;
    margin-bottom: 15px;
    text-align: start;
    font-weight: 500;
}
.step .ul li b {
    color: #dc3545;
    font-weight: 700;
}
.step .btn-big {
    max-width: fit-content;
    padding: 0 20px;
}
.step .list {
    list-style-type: decimal;
    text-align: start;
    padding-left: 20px;
}

@media (max-width: 1340px) {
  .popupsInner  {
    width: 95%;
  }
  .step {
    padding: 20px 25px;
  }
  .popupsInner h2 {
    font-size: 1.8em;
    padding: 0 10px;
  }
}
@media (max-width: 768px) {
  .popupHeader img {
    width: 60px;
    height: auto;
  }
  .step .btn {
    height: 40px;
    font-size: 12px;
    line-height: 14px;
  }
}