

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
.adminWrapper {
    min-height: 100vh;
    font-family: 'Roboto', sans-serif;
}
.adminHeader {
    background: #bababa;
    -webkit-box-shadow: 0 0 0 0.25rem rgba(231, 231, 231, 0.25);
            box-shadow: 0 0 0 0.25rem rgba(231, 231, 231, 0.25);
}
.adminHeader .inner {
    height: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    color: white;
    font-weight: bold;
}
.adminHeader p {
    font-size: 1.5rem;
    font-style: italic;
}
.adminHeader p span {
    font-size: 2rem;
}
.adcontainer {
    max-width: 1400px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;
}
.logOut {
    cursor: pointer;
    text-transform: uppercase;
}
.changingBlock {
    padding: 50px 0;
    display: grid;
    grid-template-columns: 150px 1fr;
    grid-gap: 30px;
}
.sideBar {
    border-right: 1px solid;
}
.sideItem {
    padding: 20px 10px;
    text-transform: uppercase;
    cursor: pointer;
}
@media (max-width: 768px) {
    .changingBlock {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }
    .sideBar {
        display: flex;
        border-right: none;
        border-bottom: 1px solid;
    }
    .sideItem {
        padding: 10px 20px;
        font-size: 14px;
    }
}
.changingBlock h2 {
    margin-bottom: 30px;
    padding-left: 10px;
}
.blockBox {
    margin-bottom: 40px;
    padding: 20px;
    position: relative;
}
.blockItem {
    margin-bottom: 20px;
    min-width: 320px;
    max-width: 700px;
}
.blockItem p {
    padding-left: 10px;
    margin-bottom: 5px;
}
.blockItem input {
    border: 1px solid #bababa;
    border-radius: 6px;
    width: 100%;
    padding-left: 10px;
    height: 40px;
}
.adminWrapper .btn {
    background: black;
    color: white;
    border-radius: 10px;
    border: 1px solid black;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    cursor: pointer;
}
.adminWrapper .btn:hover {
    background: white;
    color: black;
}
.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid black;
    border-color: black transparent black transparent;
    -webkit-animation: lds-dual-ring 1.2s linear infinite;
            animation: lds-dual-ring 1.2s linear infinite;
  }
  @-webkit-keyframes lds-dual-ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes lds-dual-ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  .loadBox {
    height: 100vh;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .saved {
    position: absolute;
    color: green;
    bottom: 0;
    left: 20px;
  }

  @media (max-width: 768px) {
    .blockBox {
        padding: 0;
    }
    .saved {
        bottom: -30px;
        left: 0;
    }
  }

  input[type="file"] {
    margin-top: 10px;
    border: none !important;
  }
  .blockItem img {
    margin: 10px 0;
    width: 250px;
    height: 250px;
  }