
.account-block {
    background: #fff;
    padding: 30px 0;
}
.acc-container {
    padding: 0 20px;
    width: 100%;
    margin: 0 auto;
    max-width: 1100px;
}
.account-balance,
.account-table {
    padding: 30px 0;
    border-bottom: 1px solid #d3dcd3;
}
.account-balance h3 {
    text-align: center;
    font-size: 1.5em;
    line-height: 1.2em;
    margin-bottom: 10px;
}
.ac-or {
    text-align: center;
    font-size: 2em;
    line-height: 1.2em;
    background: #f0fff1;
    color: #2ab561;
    font-weight: 800;
    padding: 0 10px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}
.ac-or sub {
    font-size: .5em;
}
.account-balance .row {
  display: grid;
  grid-template-columns: 66% 1fr;
  grid-gap: 10px;
  margin-top: 40px;
}
.profitCoin {
    text-align: center;
    margin: 0 auto 20px;

}
.account-balance .left h4 {
    font-size: 1.4em;
    line-height: 1.3em;
    text-align: center;
    margin-bottom: 1em;
}
.account-balance .text {
    margin-bottom: 3em;
    font-size: 1em;
    line-height: 1.2em;
}
.account-balance .center {
    text-align: center;
    margin-bottom: 10px;
}
.account-balance .btn {
    max-width: fit-content;
    padding: 0 20px;
    height: 45px;
    cursor: pointer;
    margin-right: auto;
    margin-left: auto;
    animation: animate 3s linear infinite;
}
.account-balance .btn i  {
    margin-right: 5px;
}
.account-balance .bit-handbitcoin {
    background-image: url('../../img/heading-icon.png');
    background-position: 0 -65px;
}
.heading-icon-retina {
    background-size: 64px 325px!important;
}
.heading-icon {
    width: 64px;
    height: 64px;
    background-repeat: no-repeat;
    background-position: 0 0;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 10px;
}
.bit-statmoney {
    background-image: url('../../img/heading-icon.png');
    background-position: 0 -260px;
}
.account-table h2,
.account-table p {
    text-align: center;
}
.account-table h2 {
    font-size: 2em;
    margin-bottom: 0.5em;
}
.p-sm-2 {
    padding: 0.5rem;
}
.fw-bold {
    font-weight: 700;
}
.table {
    width: 100%;
    caption-side: bottom;
    border-collapse: collapse;
    margin: 20px 0;
}
.table-striped>tbody>tr:nth-of-type(odd)>* {
    background: rgba(0, 0, 0, 0.05);
    color: #212529;
}
tbody, td, tfoot, th, thead, tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}


@media (max-width: 768px) {
    .ac-or {
        font-size: 1.6em;
        line-height: 1.7em;
    }
    .right {
        display: none;
    }
    .account-balance .row {
        grid-template-columns: 1fr;
    }
    .account-balance {
        padding: 10px 0;
    }
}